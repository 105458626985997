.img-li {
  display: inline-block;
  height: 100px;
  width: 100px;
  position: relative;
  border: 1px solid #15395b;
}

.img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

.img-li .ant-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}