.insert-btns {
  float: right;
  margin-bottom: 20px;
}

.ant-card-cover {
  background: #f2f2f2;
}

:global .path-box {
  display: flex;
  align-items: center;

  .dzt-breadcrumb a {
    color: #1890ff;
  }
}

:global .dzt-table-row {
  .dzt-table-cell {
    .operate-btns {
      display: none;
    }
  }

  &:hover .dzt-table-cell {
    .operate-btns {
      display: flex;
    }
  }
}

:global .doc-opt {
  .dzt-popover-arrow-content::before {
    background: #ECEFF4;
  }

  .dzt-popover-inner-content {
    padding: 0;


  }
}

:global .dzt-tree.dzt-tree-block-node .dzt-tree-list-holder-inner .dzt-tree-node-content-wrapper {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .dzt-tree-iconEle {
    vertical-align: middle;
  }

  .dzt-tree-title {
    display: inline-block;
    width: calc(100% - 24px);
    overflow: hidden;
    height: 32px;
    line-height: 32px;
    vertical-align: middle;

    span {
      word-break: break-all;
      display: inline-block;
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
@hack: true;@import "/app/src/theme/less/app.less";