// .@{ant-prefix}-btn-link {
//   padding: 0 !important;
// }
.dzt-descriptions-bordered .dzt-descriptions-view,
.dzt-descriptions-bordered .dzt-descriptions-row,
.dzt-descriptions-bordered .dzt-descriptions-item-label,
.dzt-descriptions-bordered .dzt-descriptions-item-content {
    border-color: #ccc !important;
}

.dzt-table {

    .dzt-table-row:hover,
    .selected-row-active {
        background: #e6f7ff;
    }

    .dzt-table-tbody>tr.dzt-table-row:hover>td,
    .dzt-table-tbody>tr>td.dzt-table-cell-row-hover {
        background: #e6f7ff;
    }
}

.tab-table {

    .dzt-table-cell-scrollbar {
        display: none;
    }

    .dzt-table.dzt-table-bordered>.dzt-table-container>.dzt-table-content>table,
    .dzt-table.dzt-table-bordered>.dzt-table-container>.dzt-table-header>table {
        border-top: none;
    }

    .dzt-table.dzt-table-bordered>.dzt-table-container {
        border-left: none;
    }

    .dzt-table.dzt-table-bordered>.dzt-table-container>.dzt-table-header>table>thead>tr {
        &>th {
            text-align: center;

            &:nth-last-child(1) {
                border-right: none;
            }
        }

        &:nth-last-child(1)>th {
            padding: 0;
            border-bottom: none;
        }
    }

    .model-type-item {
        width: 100%;
        text-align: center;
        padding: 16px;
        box-sizing: border-box;
        border-bottom: 1px solid #f0f0f0;

        &:hover,
        &.model-type-item-active {
            color: #1890ff;
            cursor: pointer;
        }

        &.model-type-item-active {
            border-color: #1890ff;
            border-width: 2px;
            padding: 15px;
        }
    }

    .dzt-table-thead>tr>th {
        background-color: #fff;
    }
}

@hack: true;@import "/app/src/theme/less/app.less";