.login-wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: 100% 100%;
}

.login-banner {
    background: url(theme/img/login_bg.png) center no-repeat;
    background-size: 100% 100%;
}

.login-header {
    /* text-align: center; */
    /* position: absolute; */
    /* top: 126px;
    left: 0; */
    width: 100%;
    margin-bottom: 40px;
}

.login-header .product-name {
    color: #1F5CE7;
}

.login-header .tip {
    color: #999;
}

.login-header .tip .btn {
    color: #1F5CE7;
}

@media screen and (max-width: 1280px) {
    .login-header {
        top: 50px;
    }
}

.login-header h2 {
    font-size: 30px;
    font-weight: bold;
    /* letter-spacing: 0.7em; */
    /* text-indent: 0.7em; */
    line-height: 50px;
    color: #333;
}

.login-header h3 {
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.18em;
    line-height: 20px;
}

.login-box {
    /* position: absolute; */
    /* top: 50%;
    left: 50%;
    width: 400px;
    height: 400px; */
    /* margin-left: -200px;
    margin-top: -200px; */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); */
    /* background: rgba(19, 25, 70, .55); */
}

.login-inner {
    /* padding: 40px; */
    /* margin-top: 40px; */
}

.login-title {
    text-align: center;
    padding: 0 0 20px;
    margin-bottom: 20px;
    font-size: 24px;
    color: #fff;
    letter-spacing: 0.2em;
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
    margin-top: 10px;
}

.login-box .corner {
    position: absolute;
    border: 4px solid #00B8FF;
    width: 30px;
    height: 30px;
}

.login-box .corner-left-top {
    top: 0;
    left: 0;
    border-color: #00B8FF transparent transparent #00B8FF;
}

.login-box .corner-left-bottom {
    bottom: 0;
    left: 0;
    border-color: transparent transparent #00B8FF #00B8FF;
}

.login-box .corner-right-top {
    top: 0;
    right: 0;
    border-color: #00B8FF #00B8FF transparent transparent;
}

.login-box .corner-right-bottom {
    bottom: 0;
    right: 0;
    border-color: transparent #00B8FF #00B8FF transparent;
}

.login-box .ant-form-item {
    margin-bottom: 24px;
}

.login-box .ant-input-affix-wrapper-lg {
    padding: 8px 20px;
}

.login-form-button {
    background: linear-gradient(0deg, #0177E4, #00B8FF);
    border-radius: 6px;
    padding: 5px 15px;
    height: 46px;
    border: none;
    font-size: 20px;
    letter-spacing: 0.5em;
}

.login-form-button:hover {
    background: linear-gradient(0deg, #0282fa, #0cb9fd);
}

.login-box .ant-input-prefix {
    margin-right: 15px;
}

.login-box .ant-input-affix-wrapper>input.ant-input {
    background: transparent;
    /* color: #fff; */
    font-size: 16px;
}

.login-box .ant-input-affix-wrapper>input.ant-input:-internal-autofill-selected {
    background: transparent !important;
    -webkit-text-fill-color: #fff !important;
    -webkit-box-shadow: 0 0 0px 1000px #08103E inset;
    -webkit-border-radius: 0;
    font-size: 16px;
}

.login-box .ant-input-affix-wrapper>input.ant-input:-webkit-autofill {
    background: transparent;
    -webkit-box-shadow: 0 0 0px 1000px #08103E inset;
    -webkit-border-radius: 0;
    -webkit-text-fill-color: #fff;
    font-size: 16px;
}

.login-box .ant-input-affix-wrapper>input.ant-input:-webkit-autofill:focus {
    background: transparent;
    -webkit-text-fill-color: #fff;
    -webkit-border-radius: 0;
    font-size: 16px;
}

.login-box .ant-input-affix-wrapper>input.ant-input::-webkit-input-placeholder {
    color: #ccc;
}

.login-box .ant-input-affix-wrapper>input.ant-input:-moz-placeholder {
    color: #ccc;
}

.login-box .ant-input-affix-wrapper>input.ant-input::-moz-placeholder {
    color: #ccc;
}

.login-box .ant-input-affix-wrapper>input.ant-input:-ms-input-placeholder {
    color: #ccc;
}

.login-box .ant-form-item-explain {
    position: absolute;
    top: 100%;
}