body {
  background: #F2F2F2;
}

.logo {
  height: 80px;
  /* line-height: 80px; */
  font-size: 18px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  /* background: rgba(255, 255, 255, 0.2);
  background: #000C17 url('../../theme/img/logo.png') center no-repeat;
  background: #1E3A6B;
  background: #000C17;
  background: #ECEFF4; */
  /* background-size: 20%; */
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: #eee;
  overflow: hidden;
}

.mkt-logo {
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
}

.logo-img {
  display: inline-block;
  vertical-align: middle;
  height: 50px;
}

.logo span {
  display: inline-block;
  vertical-align: middle;
  /* margin-left: 15px; */
}

.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 200px;
  overflow-y: auto;
  z-index: 200;
}

.slidebar1 {
  z-index: 1;
}

.copyright {
  position: absolute;
  bottom: 0;
  text-align: center;
  padding: 20px 10px;
  font-size: 12px;
}

.cloud-content-wrap {
  /* padding: 16px; */
  margin-left: 200px;
  flex: auto;
  width: calc(100% - 200px);
}

.content-wrap {
  position: absolute;
  top: 0;
  right: 0;
  left: 220px;
  bottom: 0;
  overflow: auto;
  background: #f2f2f2;
}

#root {
  min-height: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main_content {
  flex: 1;
}

/* .sidebar {
      overflow: auto;
      height: 100vh;
      position: fixed;
      left: 0;
  } */

.header {
  padding: 0 20px;
  background: #fff;
  border-bottom: 1px solid #f5f5f5;
  overflow: hidden;
  text-align: center;
}

.inner {
  width: 1440px;
  margin: 0 auto;
}

.header-title {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 20px;
  -webkit-box-reflect: below -5px -webkit-linear-gradient(transparent, transparent 40%, rgba(0, 0, 0, .3));
  color: #333333;
  font-family: OPPOSans-R;
  float: left;
}

.header-right {
  float: right;
  display: flex;
  align-items: center;
}

.main-content {
  padding: 16px;
  background: #F6F9FC;
}

.breadcrumb {
  margin: 12px 0;
}

.content {
  min-height: calc(100vh - 265px);
  position: relative;
  /* background: #fff; */
}

.corner {
  position: absolute;
  width: 10px;
  height: 10px;
}

.corner-top-left {
  top: -2px;
  left: -2px;
}

.corner-top-left::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 3px;
  background: #00DBEF;
}

.corner-top-left::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 10px;
  background: #00DBEF;
}

.corner-top-right {
  top: -2px;
  right: -2px;
}

.corner-top-right::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 3px;
  background: #00DBEF;
}

.corner-top-right::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 3px;
  height: 10px;
  background: #00DBEF;
}

.corner-bottom-left {
  bottom: -2px;
  left: -2px;
}

.corner-bottom-left::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 10px;
  height: 3px;
  background: #00DBEF;
}

.corner-bottom-left::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 3px;
  height: 10px;
  background: #00DBEF;
}

.corner-bottom-right {
  bottom: -2px;
  right: -2px;
}

.corner-bottom-right::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10px;
  height: 3px;
  background: #00DBEF;
}

.corner-bottom-right::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 3px;
  height: 10px;
  background: #00DBEF;
}

.no-back {
  background: transparent;
  padding: 0;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.footer {
  text-align: center;
  display: block;
  width: 100%;
  padding: 40px 0;
  background: #fff;
  color: #999;
}

.footer span {
  margin-right: 5px;
}

.footer-info>div {
  color: #333;
  font-size: 14px;
  margin-top: 11px;
  vertical-align: middle;
}

.footer-info>div a {
  display: inline-block;
  vertical-align: middle;
  color: #333;
}

.footer-info .icon-security {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.page {
  padding: 20px;
  background: #fff;
  width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
  min-height: 800px;
}

.page.no-back {
  background: transparent;
  padding: 0px;
}

@media screen and (max-width: 1200px) {
  .content-wrap {
    left: 48px;
    transition: all 0.3s;
  }

  .cloud-content-wrap {
    margin-left: 48px;
    width: calc(100% - 48px);
  }

  .header-title {
    display: none;
  }
}