@font-face {
    font-family: OPPOSans-R;
    src: url(./theme/font/OPPOSans-R-2.ttf)
}

html,
body {
    /* overflow: hidden; */
    background: #F6F9FC;
}

body {
    margin: 0;
    font-family: OPPOSans-R, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background: #ecf0f5!important; */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.aigl-key {
    color: #a11;
}

.aigl-string {
    color: #2a9292;
}

.aigl-boolean {
    color: #aa573c;
}

.aigl-number {
    color: #aa573c;
}

.aigl-null {
    color: #aa573c;
}

label {
    /* max-width: 100%; */
    white-space: normal;
    overflow: hidden;
}

.under_construction {
    text-align: center;
    line-height: 500px;
    font-size: 40px;
    color: #ddd;
}

.content-header {
    padding: 20px 20px;
    border-bottom: 1px solid #eee;
    background: #fefefe;
}

.content-header h2 {
    margin: 0;
    position: relative;
    padding-left: 20px;
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
}

.content-header h2::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background: #1890ff;
    border-radius: 4px;
}

.content-body {
    padding: 20px;
    background-color: #fff;
}

.ant-btn-link {
    padding: 0 !important;
}

.ant-table-small>.ant-table-content>.ant-table-body {
    margin: 0 !important;
}

td {
    word-break: break-all;
}

.path-box .ant-breadcrumb-separator {
    margin: 0;
}

.path-box a {
    color: rgba(0, 0, 0, 0.45);
}

.ant-form-text {
    word-break: break-all;
}

.ant-menu-item a {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ant-form-item-label {
    text-overflow: ellipsis;
}

.ant-btn {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important;
}

a.btn-action {
    display: inline-block;
}

a.btn-action.ellipsis {
    width: 4em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ant-tooltip-inner {
    word-break: break-all;
}

.table-wrapper .ant-table-thead>tr>th {
    border: none;
    background: none;
}

.table-wrapper .ant-table-thead>tr>th,

.table-wrapper .ant-table-tbody>tr>td {
    text-align: center;
    border: none;
}

.ant-table-wrapper {
    clear: both;
}

.overflowOverlay {
    overflow-x: auto;
    overflow-y: auto;
    overflow-x: overlay;
    overflow-y: overlay;
}

.ant-space .ant-form-item {
    margin-bottom: 5px;
}

.euip-col {
    width: 300px;
}

@media screen and (max-width: 1280px) {
    .euip-col {
        width: 200px;
    }
}

.ant-form-item-control-input-content img {
    max-width: 100%;
}

.ant-image {
    width: 100%;
}

.ant-carousel .slick-slide img {
    width: 100%;
}

.ant-image-img {
    object-fit: cover;
}

.ant-image {
    height: 100%;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
    background: rgba(0, 0, 0, 0.15) !important;
    border-radius: 40px;
    width: 40px !important;
    height: 40px !important;
}

.ant-card-bordered {
    border-color: #ebebeb !important;
}

.page .ant-input[disabled] {
    background: none;
    color: #666;
}

.ant-list-sm .ant-list-item {
    padding: 10px 0 !important;
}

.ant-layout-header .ant-menu {
    text-align: center;
}

.react-resizable {
    position: relative;
    background-clip: padding-box;
    overflow-x: hidden;
}

.react-resizable-handle {
    position: absolute;
    width: 10px;
    height: 100%;
    bottom: 0;
    right: -5px;
    cursor: col-resize;
    z-index: 1;
}

.label-wrapper .react-resizable-handle {
    right: 0;
}