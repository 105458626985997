.drop-down {
  /* background-color: #00102F; */
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, .08), 0 9px 28px 0 rgba(0, 0, 0, .05), 0 12px 48px 16px rgba(0, 0, 0, .03);
  width: 400px;
}

.drop-down-actions {
  display: flex;
  justify-content: center;
}

.drop-down-actions a {
  display: inline-block;
  width: 50%;
  text-align: center;
}

.drop-down-actions a:not(:only-child):last-child {
  border-left: 1px solid #f0f0f0;
}