@import '~@om-tlh/bee/es/style/index.less';

@import "./variables.less";
// @import '~@om-tlh/bee/dist/@om-tlh/bee.compact.less'; // 引入官方提供的紧凑 less 样式入口文件
// @import './dark.less';


.@{ant-prefix}-carousel .slick-dots-bottom {
  background: rgba(0, 0, 0, 0.45);
  padding: 10px;
  margin: 0;
  bottom: 0;
}

.@{ant-prefix}-switch {
  min-width: 70px;
}

.@{ant-prefix}-carousel .slick-prev,
.@{ant-prefix}-carousel .slick-prev:hover,
.@{ant-prefix}-carousel .slick-prev:focus {
  left: 10px;
  z-index: 2;
  color: #1890FF;
  font-size: 20px;
  height: 30px;
}

.@{ant-prefix}-carousel .slick-next,
.@{ant-prefix}-carousel .slick-next:hover,
.@{ant-prefix}-carousel .slick-next:focus {
  right: 10px;
  z-index: 2;
  color: #1890FF;
  font-size: 20px;
  height: 30px;
}

.@{ant-prefix}-layout-sider {
  background: #ECEFF4;
}

.@{ant-prefix}-menu {
  background: #ECEFF4;
}

.@{ant-prefix}-btn-link {
  padding: 0;
}

.@{ant-prefix}-menu-sub.@{ant-prefix}-menu-inline {
  background: darken(#ECEFF4, 2%);
}

.@{ant-prefix}-menu:not(.@{ant-prefix}-menu-horizontal) .@{ant-prefix}-menu-item-selected {
  background: #E7F0FF;
}

.@{ant-prefix}-menu-inline .@{ant-prefix}-menu-item::after {
  left: 0;
  border-color: #0079FE;
}

// 还原UI

.@{ant-prefix}-table-thead>tr>th {
  background: #F5F7FD;
}

.@{ant-prefix}-btn-default {
  background: #F5F7FD;
}