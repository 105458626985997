ul.toolbar-wrapper {
  overflow: hidden;
  margin: auto;
  text-align: center;
  padding-left: 5px;
  padding-top: 5px;
  
  .toolbar-item {
    display: inline-block;
    width: 4em;
    // border: 1px solid #ddd;
    // border: 1px solid #ddd;
    padding: 10px;
    margin-right: 5px;
    margin-bottom: 5px;

    &:hover {
      color: #fff;
      background-color: #1890ff;
      cursor: pointer;
    }

    &.disabled {
      background-color: transparent;
      color: rgba(0, 0, 0, 0.25);
      cursor: not-allowed;
    }
  }

  li.active {
    color: #fff;
    background-color: #1890ff;
    cursor: pointer;
  }
}
@hack: true;@import "/app/src/theme/less/app.less";