.insert-btns {
  float: right;
  margin-bottom: 20px;
}

.ant-card-cover {
  background: #f2f2f2;
}

:global .path-box {
  display: flex;
  align-items: center;

  .dzt-breadcrumb a {
    color: #1890ff;
  }
}

:global .dzt-table-row {
  .dzt-table-cell {
    .operate-btns {
      display: none;
    }
  }

  &:hover .dzt-table-cell {
    .operate-btns {
      display: flex;
    }
  }
}

:global .files-wrapper {
  .dzt-card-head {
    min-height: 24px;
  }

  .dzt-card-head-title,
  .dzt-card-extra {
    padding: 0px 0;
  }
}
@hack: true;@import "/app/src/theme/less/app.less";