.virtual-table .ant-table-container:before,
.virtual-table .ant-table-container:after {
  display: none;
}

.virtual-table-cell {
  box-sizing: border-box;
  padding: 16px;
  border-bottom: 1px solid #e8e8e8;
  background: #FFF;

  &:hover {
    background-color: #f00;
  }
}

[data-theme="dark"] .virtual-table-cell {
  box-sizing: border-box;
  padding: 16px;
  border-bottom: 1px solid #303030;
  background: #141414;
}

.card-mode-cata {
  border-radius: 10px;
  margin-bottom: 20px;
  margin-right: 20px;

  .dzt-card-body {
    padding-top: 34px;
  }

  .operate-btns {
    display: none;
    position: absolute;
    left: 12px;
    top: 6px;
    right: 12px;
    z-index: 10;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 24px);
  }

  &:hover {
    background-color: rgb(236, 239, 244);

    .operate-btns {
      display: flex;
    }
  }

  &.selected-item {
    background-color: rgb(236, 239, 244);

    .operate-btns {
      display: flex;
    }
  }
}
@hack: true;@import "/app/src/theme/less/app.less";