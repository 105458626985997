.loading-page {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
}

.loading-icon {
  margin: 200px auto;
  text-align: center;
}