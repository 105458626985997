.@{ant-prefix}-carousel .big-dot li {
  height: 16px;
}

.@{ant-prefix}-carousel .big-dot li button {
  height: 100%;
  border-radius: 8px;
  background-color: #ccc;
  border: 2px solid #fff;
}

.@{ant-prefix}-carousel .big-dot li.slick-active {
  box-sizing: border-box;
  background-color: #fff;
  /* box-shadow: 0 0 5px #ccc; */
  border: 2px solid #ccc;
  border-radius: 10px;
}
@hack: true;@import "/app/src/theme/less/app.less";