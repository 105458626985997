.editable-cell-value-wrap {
    width: 100%;
    padding: 4px 11px;
    box-sizing: border-box;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}
@hack: true;@import "/app/src/theme/less/app.less";