.@{ant-prefix}-layout-sider-collapsed .logo span {
  display: none;
}

.@{ant-prefix}-menu-vertical.@{ant-prefix}-menu-sub,
.@{ant-prefix}-menu-vertical-left.@{ant-prefix}-menu-sub,
.@{ant-prefix}-menu-vertical-right.@{ant-prefix}-menu-sub {
  min-width: 120px;
}

.@{ant-prefix}-menu-sub.@{ant-prefix}-menu-inline {
  background-color: #fff !important;
}

// #contentWrap {
//   .@{ant-prefix}-divider {
//     border-color: #999;
//   }
// }

// .header .@{ant-prefix}-menu-horizontal:not(.@{ant-prefix}-menu-dark)>.@{ant-prefix}-menu-item:hover {
//   color: #666 !important;
// }
@hack: true;@import "/app/src/theme/less/app.less";