.swiper-container {
  width: 100%;
  height: 725px;
  margin-left: auto;
  margin-right: auto;
  --swiper-theme-color: #1890ff;
}

.gallery-top {
  // height: 80%;
  width: 100%;

  .swiper-wrapper {
    overflow: hidden;
  }

  .swiper-slide {
    overflow: auto;
    border: 1px solid #eee;
  }
}

.gallery-thumbs {
  width: 100%;
  height: 200px;
  float: left;
  padding: 10px 0;
  margin-top: 20px;

  .swiper-slide {
    box-sizing: border-box;
    width: 20%;
    height: 100%;
    border-radius: 5%;
    transition: 300ms;
    transform: scale(1);
    opacity: 0.4;
  }

  .swiper-slide-active,
  .swiper-slide-duplicate-active {
    transform: scale(1.1);
    border: 1px solid #1890ff;
    opacity: 1;
  }
}

#canvas-container {
  position: relative;
  // top: 50%;
  // margin: auto;
  // transform: translateY(-50%);
  width: 100%;
  height: 100%;
}


.input-select {
  // display: none;
  opacity: 0;
  position: absolute;
  width: 100%;
  max-height: 0;
  overflow-y: auto;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  top: 26px;
  z-index: 100;
  text-align: left;
  line-height: 2;
  background-color: #fff;
  transition: all .3s;

  .input-select-option {
    cursor: pointer;
    padding: 4px 10px;

    &:hover {
      background: #e6f7ff;
    }
  }

  .input-select-option-active {
    background: #e6f7ff;
  }
}

.input-select-show {
  opacity: 1;
  max-height: 110px;
  // display: block;
}

.input-select-input {
  .dzt-input-suffix {
    transition: all .3s;
  }
}

.input-select-input-foucs {
  .dzt-input-suffix {
    transform: rotate(180deg);
    transform-origin: 50% 25%;
  }
}

.label-list {
  float: left;
  // padding-left: 10px;
  // padding-top: 10px;

  .selectedlabel-title {
    margin: 10px;
    margin-top: 0;
    margin-left: 0;
    font-weight: bolder;
    text-align: left;
  }

  ul.selected-image-labels {
    .selected-image-label-item {
      position: relative;
      overflow: hidden;
      height: 36px;
      line-height: 36px;
      border-bottom: 1px solid #e8e8e8;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: left;

      .label-name {
        position: relative;
        width: 75%;
        float: left;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .label-pop {
        overflow: hidden;

        .label-color {
          float: left;
          width: 1em;
          height: 1em;
          border-radius: 50%;
          margin-top: 5px;
        }

        .label-operates {
          display: none;
          float: right;
        }
      }

      &:hover,
      &.selected-label-active {
        background: #e6f7ff;

        .label-operates {
          display: block;
        }
      }
    }
  }
}

#label-container {
  position: relative;
  text-align: center;
  overflow: hidden;
}

.canvas-context-menu {

  .dzt-menu-light .dzt-menu-item:hover,
  .dzt-menu-light .dzt-menu-item-active,
  .dzt-menu-light .dzt-menu:not(.dzt-menu-inline) .dzt-menu-submenu-open,
  .dzt-menu-light .dzt-menu-submenu-active,
  .dzt-menu-light .dzt-menu-submenu-title:hover {
    background-color: #e6f7ff;
  }
}

.resizer {
  width: 10px;
  height: 100%;
  margin-left: -5px;
}
@hack: true;@import "/app/src/theme/less/app.less";